/**
 * 请求成功状态码
 */
export const SUCCESS_CODE = 200

export const AUTH_CODE = 401

/**
 * 请求contentType
 */
export const CONTENT_TYPE: AxiosContentType = 'application/json'

/**
 * 请求超时时间
 */
export const REQUEST_TIMEOUT = 120000

/**
 * 不重定向白名单
 */
export const NO_REDIRECT_WHITE_LIST = ['/login']

/**
 * 不重置路由白名单
 */
export const NO_RESET_WHITE_LIST = ['Redirect', 'Login', 'NoFind', 'Root']

/**
 * 表格默认过滤列设置字段
 */
export const DEFAULT_FILTER_COLUMN = ['expand', 'selection']

/**
 * 是否根据headers->content-type自动转换数据格式
 */
export const TRANSFORM_REQUEST_DATA = true

/**
 * 全局图标前缀
 */
export const ICON_PREFIX = 'vi-'

export const valueExistsInArray = (arr, value)=> {
    return arr.some(function recurse(item) {
        // 如果找到相等的值，返回true
        if (item.route === value) {
            return true
        }
        // 如果item是数组，则递归检查
        if (Array.isArray(item)) {
            return recurse(item)
        }
        // 否则，返回false
        return false
    })
}